(function ($, window, document, undefined) {

  'use strict';

  $(window).on('scroll load', function() {
		var scrollDistance = ($(window).scrollTop())+400;

		$('.section').each(function(i) {
				if ($(this).position().top <= scrollDistance) {
						$('.menu-nav a.active').removeClass('active');
						$('.menu-nav a').eq(i).addClass('active');
				}
		});
  });

  $(function() {

    $('.burger').click(function(e){
      e.preventDefault();
      $('html').toggleClass('menu-active');
    });

    $(document).on('click', 'a[href^="#"]', function(event) {
      event.preventDefault();

      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
      $('html').removeClass('menu-active');
    });

    window.sr = ScrollReveal();

    sr.reveal('header', {
            viewFactor: 0.5,
            delay: 200,
            scale: 1,
            duration: 700,
            opacity: 0
        }
    );

    sr.reveal($('header').find('img'), {
            viewFactor: 0.5,
            delay: 400,
            scale: 1,
            duration: 700,
            opacity: 0
        }
    );

    sr.reveal($('.description').find('img'), {
            viewFactor: 0.4,
            scale: 1,
            duration: 700,
            opacity: 0
        }
    );

    sr.reveal($('.cabinet').find('.container'), {
            viewFactor: 0.2,
            scale: 1,
            duration: 700,
            opacity: 0
        }
    );

    sr.reveal($('.cabinet').find('img'), {
            viewFactor: 0.4,
            scale: 1,
            delay: 200,
            duration: 700,
            opacity: 0
        }
    );

    sr.reveal($('.specialites').find('.block-specialites'), {
            viewFactor: 0.4,
            scale: 1,
            delay: 200,
            duration: 700,
            opacity: 0
        }
    );

    sr.reveal($('.sport').find('.picture'), {
            viewFactor: 0.4,
            scale: 1,
            duration: 700,
            opacity: 0
        }
    );

    sr.reveal($('.sport').find('.remotivation'), {
            viewFactor: 0.1,
            scale: 1,
            delay: 200,
            duration: 700,
            opacity: 0
        }
    );

    sr.reveal($('.contact').find('.container'), {
            viewFactor: 0.3,
            scale: 1,
            duration: 700,
            opacity: 0
        }
    );
  });

})(jQuery, window, document);
